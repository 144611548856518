import { useTranslation } from "next-i18next";
import Image from "next/image";
import { BiCopyright } from "react-icons/bi";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="flex flex-col justify-center items-center py-12 bg-primary relative">
      <div>
        <Image src="/images/WebFlex.png" alt="Logo" width={150} height={40} />
      </div>

      <div>
        <div className="text-1xl pb-4 text-center leading-10">
          <h2 className="">{t("footer-montreal-based")}</h2>
          <h2 className="text-indigo-600 pr-2">
            {t("footer-web-development-company")}
          </h2>
        </div>
      </div>
      <div className="flex py-7">
        <div>Built by WebFlex</div>
        <div className="px-2">
          <BiCopyright size={30} color={"#4F46E5"} />
        </div>
        <div>2022</div>
      </div>
    </footer>
  );
};

export default Footer;
